import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant'
import { DropdownMenu, DropdownItem } from 'vant';
import { Popover } from 'vant';
// main.js 或者相应的组件文件中
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

import 'vant/lib/index.css';
import 'swiper/css/bundle'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import VideoPlayer from 'vue-video-player/src'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import 'amfe-flexible'
import { Toast } from 'vant';
import axios from "axios"
import { Popup } from 'vant';
router.beforeEach((to, from, next) => {
  if (to.path) {
    window._hmt.push(['_trackPageview', 'http://bjdcphone.dashengclass.com/#' + to.fullPath]);
  }
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next();
})

Vue.config.productionTip = false
Vue.use(Vant)
Vue.use(VideoPlayer)
Vue.use(Toast)
Vue.use(Popup);
Vue.use(Popover);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Viewer);
axios.get('/config.json').then(res => {
  Vue.prototype["baseURL"] = res.data.imgIp;
  Vue.prototype["baseURLs"] = res.data.baseUrl;

  new Vue({
    router,
    render: h => h(App),
  }).$mount('#app')
})
