<template>
  <div class="Scroll-to-top" v-if="scroll" @click="toTop"></div>
</template>
<script>
export default {
  data() {
    return {
      height: "",
      scroll: "",
    };
  },
  mounted() {
    this.height = window.screen.height;
    window.addEventListener("scroll", this.menu);
  },
  methods: {
    menu() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.scroll = scrollTop >= this.height;
    },
    toTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>
<style lang="scss">
.Scroll-to-top {
  position: fixed;
  right: 20px;
  bottom: 290px;
  width: 100px;
  height: 100px;
  background: url("@/assets/imgs/top.png") no-repeat;
  background-size: 100%;
  z-index: 999999999999;
}
</style>